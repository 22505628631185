<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-patient-bar @patientLoaded="onPatientLoaded" @patientUnloaded="onPatientUnloaded"></v-patient-bar>
      </v-col>
      <v-col cols="12" v-if="currentPatient">
        <v-model-table model-name="Insurances" model-title="Insurances"
          :model-api="['models','Patient','PatientsInsurancesItemizes']"
          :model-api-search="{'hn': currentPatient.hn}"
          :headers="headers" :insertable="false"
          expandable
        >
          <template v-slot:item.priority="props">
            <span v-if="props.item.isTechnicalActive && props.item.isActive">{{props.item.priority}}</span>
            <v-icon v-else>mdi-lock-alert</v-icon>
          </template>
          <template v-slot:item.payer="props">
            <v-label-master group-key="payerType" :item-code="props.item.payerType"></v-label-master>
            <label v-if="props.item.payer">({{props.item.payer.payerName}})</label>
          </template>
          <template v-slot:item.beginDate="props">
            <v-label-datetime short-date :date-time="props.item.beginDate"></v-label-datetime>
          </template>
          <template v-slot:item.endDate="props">
            <v-label-datetime short-date :date-time="props.item.endDate"></v-label-datetime>
          </template>
          <template v-slot:item.isChargeToPatient="props">
            <v-icon class="text-center">{{ (props.item.isChargeToPatient) ? 'check' : 'close' }}</v-icon>
          </template>
          <template v-slot:item.limit="props">
            {{ (props.item.limit && props.item.limit>0) ? props.item.limit.toFixed(2) : '-' }}
          </template>
          <template v-slot:item.amount="props">
            <label class="body-2 font-weight-bold red--text text--darken-1" v-if="props.item.limit && props.item.limit>0 && props.item.amount>props.item.limit">
              {{ props.item.amount.toFixed(2) }}
            </label>
            <label class="body-2 font-weight-bold green--text text--darken-3" v-else>
              {{ props.item.amount.toFixed(2) }}
            </label>
          </template>
          <template v-slot:expanded-item="{ headers,item,actions }">
            <td :colspan="headers.length" class="pa-2">
              <v-enhance-table
                :items="item.summarized_transactions"
                :headers="transactionHeader"
              >
                <template v-slot:item.productCode="props">
                  {{ props.item.productCode }} {{ props.item.product.productName }}
                </template>
                <template v-slot:item.discount="props">
                  {{ (props.item.discount==0 || props.item.discount==null) ? "-" : props.item.discount+"%" }}
                </template>
                <template v-slot:item.totalPrice="props">
                  {{ props.item.totalPrice.toFixed(2) }}
                </template>
                <template v-slot:item.totalDiscount="props">
                  {{ props.item.totalDiscount.toFixed(2) }}
                </template>
                <template v-slot:item.finalExcessPrice="props">
                  {{ props.item.finalExcessPrice.toFixed(2) }}
                </template>
                <template v-slot:item.finalCoverPrice="props">
                  {{ props.item.finalCoverPrice.toFixed(2) }}
                </template>
                <template v-slot:item.finalPrice="props">
                  {{ props.item.finalPrice.toFixed(2) }}
                </template>
              </v-enhance-table>
            </td>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import patient from '@/modules/helperPatient'
import patientLoader from '@/components/mixins/patientLoader'
import helperItem from '@/modules/helperItem'

export default {
  mixins: [patientLoader],
  data: ()=>({
    currentPatient: undefined,
    headers: [
      {
        text: 'ลำดับ',
        value: 'priority',
        align: 'center',
        class: 'body-2',
        width: '100px'
      },
      {
        text: 'สิทธิ์/ประกัน',
        value: 'payer',
        class: 'body-2',
      },
      {
        text: 'สำรองจ่าย',
        value: 'isChargeToPatient',
        class: 'body-2',
      },
      {
        text: 'เริ่มต้น',
        value: 'beginDate',
        class: 'body-2'
      },
      {
        text: 'สิ้นสุด',
        value: 'endDate',
        class: 'body-2'
      },
      {
        text: 'Ref',
        value: 'contractNo',
        class: 'body-2'
      },
      {
        text: 'Limit',
        value: 'limit',
        class: 'body-2'
      },
      {
        text: 'ยอดเบิก',
        value: 'amount',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    transactionHeader: [
      {
        text: 'Product',
        value: 'productCode',
        class: 'body-2'
      },
      {
        text: 'CGD Code',
        value: 'product.cgdCode',
        class: 'body-2'
      },
      {
        text: 'Quantity',
        value: 'quantity',
        class: 'body-2',
      },
      {
        text: 'Sold Price',
        value: 'soldPrice',
        class: 'body-2'
      },
      {
        text: 'Total Price',
        value: 'totalPrice',
        class: 'body-2'
      },
      {
        text: 'Discount',
        value: 'totalDiscount',
        class: 'body-2'
      },
      {
        text: 'Final Price',
        value: 'finalPrice',
        class: 'body-2'
      },
      {
        text: 'Excess Price',
        value: 'finalExcessPrice',
        class: 'body-2'
      },
      {
        text: 'Cover Price',
        value: 'finalCoverPrice',
        class: 'body-2'
      },
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  watch: {
    hn: function(newVal) {
      this.loadPatient(this.hn)
    }
  },
}
</script>

<style lang="css" scoped>
</style>
